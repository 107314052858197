import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { RequestService } from '../service/request.service';
import { GetterService } from '../service/getter-service.service';
import { popupService } from './popup.service';
import { ApiService } from '../service/api.service';
import { Subscription } from 'rxjs';
declare let fbq: any;

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit, OnDestroy {
  /* isDiscountedItem: boolean = false; */
  MenuBuilder: boolean = false;
  showPopup: boolean = false;
  dineIn: boolean = false;
  eatIn: boolean = false;
  addedTocart: boolean = false;
  filteredProduct: any;
  filteredRecipe: any;
  renderedExtraList: any[] = [];
  renderExtralistChooseOnes: number;
  openForNonExtras: boolean;
  @ViewChild('description', { static: false }) description: ElementRef;
  @Output() closeExtrasModal = new EventEmitter<boolean>();
  @Output() addedToCart = new EventEmitter<any>();
  extraArray: any[] = [];
  quantity: any;
  recipeTotal = 0;
  extrasTotal = 0;

  extraArrayChooseOnes: number = 0;
  recipes: any;
  recipePrice: number = 0;
  selectedRecipeTitle: string = '';

  productTotal: any;
  productPrice: number = 0;
  /* Menu Builder */
  selectedOptionId: number = 0;
  selectedOptionsArray = [0];
  selectedOptionString: String = '0';
  disableCartbtn: boolean = true;

  /* Dynamic styles */
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor;
  PopupOnKgItems: boolean;
  webCusSub: Subscription;
  popupSub: Subscription;
  mergePopups: boolean;
  renderedMenuBuilder: any;
  showExtrasAfterMenuBuilder: boolean;
  addtoCartButtonText: string;
  limitReached: boolean;
  addToCartRemainingLimit: number;
  timeout: NodeJS.Timeout;

  constructor(
    public _requestService: RequestService,
    private _local: GetterService,
    private changeDetector: ChangeDetectorRef,
    private popupService: popupService,
    private apiService: ApiService,
    private renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
    this.popupSub.unsubscribe();
  }

  ngOnInit(): void {
    this.getWebCustomer();
    this.setData();
  }

  setData() {
    this.popupSub = this.popupService.popUpData.subscribe((data) => {
      this.dineIn = data.dineIn;
      this.extraArrayChooseOnes = 0;
      this.showExtrasAfterMenuBuilder = false;
      this.addToCartRemainingLimit = 0;
      this.extraArray = [];
      if (data.quantity) {
        this.quantity = data.quantity;
      } else {
        this.quantity = 1;
      }
      this.recipeTotal = 0;
      this.extrasTotal = 0;
      this.recipePrice = 0;
      this.renderedExtraList = [];
      this.selectedOptionId = 0;
      this.selectedOptionsArray = [0];
      this.selectedOptionString = '0';
      this.disableCartbtn = true;
      this.MenuBuilder = data.MenuBuilder;
      this.mergePopups = data.mergePopups;
      this.eatIn = data.eatIn;
      this.filteredProduct = data.filteredProduct;
      this.filteredRecipe = data.filteredRecipe;
      this.renderedExtraList = data.renderedExtraList;
      this.renderedMenuBuilder = data.renderedMenuBuilder;
      this.renderExtralistChooseOnes = data.renderExtralistChooseOnes;
      this.openForNonExtras = data.openForNonExtras;

      this.recipeTotal = this.recipePrice = this.filteredRecipe
        ? this.filteredRecipe.RecipePrice
        : this.filteredProduct.ProductOldPrice;
      this.recipeTotal = this.recipeTotal * this.quantity;
      if (
        this.filteredProduct?.ProductUnits?.toLowerCase() == 'kg' &&
        this.PopupOnKgItems
      ) {
        this.quantity = '1.0';
        this.recipeTotal = this.recipePrice =
          (this.filteredRecipe
            ? this.filteredRecipe.RecipePrice
            : this.filteredProduct.ProductOldPrice) * this.quantity;
      }

      let existing = this._local.get('cartRecipeItems')
        ? JSON.parse(this._local.get('cartRecipeItems'))
        : [];
      this.limitReached = false;
      if (existing.length && this.filteredRecipe) {
        existing.forEach((val) => {
          if (
            val.RecipeId == this.filteredRecipe.RecipeId &&
            this.filteredRecipe.RecipeMaxBuyAllowed
          ) {
            this.addToCartRemainingLimit =
              this.filteredRecipe.RecipeMaxBuyAllowed - val.RecipeQuantity;
            if (val.RecipeQuantity >= this.filteredRecipe.RecipeMaxBuyAllowed) {
              this.limitReached = true;
            }
          }
        });
      }
      if (this.limitReached) {
        setTimeout(() => {
          this.limitReached = false;
        }, 2000);
        return;
      }

      if (!this.renderedExtraList.length && !this.openForNonExtras) {
        this.addToCart(
          this.filteredRecipe ? this.filteredRecipe : this.filteredProduct,
          this.filteredRecipe ? 'recipe' : 'product'
        );
      } else {
        this.showPopup = true;
        setTimeout(() => {
          if (!this.filteredProduct) return;
          this.renderer.setProperty(
            this.description.nativeElement,
            'innerHTML',
            this.filteredProduct?.ProductContent
          );
        }, 1);
      }
    });
  }

  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.PopupOnKgItems = res.TemplateData?.AdditionalDetail?.PopupOnKgItems;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.addtoCartButtonText =
        res.TemplateData.CheckoutButtonsText?.PopupAddtoCartButtonText;
    });
  }

  addToCart(selectedItem: any, flag: any) {
    let data: any;
    let existing: any[] = [];
    switch (flag) {
      case 'recipe':
        data = {
          RecipeId: selectedItem.RecipeId,
          RecipeTitle: selectedItem.RecipeTitle,
          RecipePrice: this.recipePrice + this.extrasTotal,
          recipeTotal: this.recipeTotal,
          RecipeImage: selectedItem.RecipeImage,
          RecipeQuantity: this.quantity,
          IsDiscountedItem: selectedItem.isDiscountedItem,
          RecipeExtraObject: this.extraArray,
          socialDiscountItem: selectedItem.socialDiscountItem,
          ForDelivery: selectedItem?.ForDelivery,
          ForCollection: selectedItem?.ForCollection,
          ForDineIn: selectedItem?.ForDineIn,
          ForEatIn: selectedItem?.ForEatIn,
          RecipeMaxBuyAllowed: this.filteredRecipe.RecipeMaxBuyAllowed,
        };

        existing = JSON.parse(this._local.get('cartRecipeItems'));

        if (existing != null && existing.length != 0) {
          let match: boolean = false;
          if (
            !selectedItem.isDiscountedItem ||
            !selectedItem.socialDiscountItem
          ) {
            existing.forEach((val) => {
              if (val.socialDiscountItem) return;
              if (val.RecipeId == selectedItem.RecipeId) {
                if (val.RecipeExtraObject.length == this.extraArray.length) {
                  let j: number = 0;
                  for (let i = 0; i < this.extraArray.length; i++) {
                    if (
                      val.RecipeExtraObject[i].Title ===
                      this.extraArray[i].Title
                    ) {
                      j++;
                    }
                  }
                  if (j >= this.extraArray.length) {
                    match = true;
                    val.RecipeQuantity = val.RecipeQuantity + this.quantity;
                    val.IsDiscountedItem = selectedItem.IsDiscountedItem;
                    val.recipeTotal =
                      val.recipeTotal + val.RecipePrice * this.quantity;
                  }
                }
              }
            });
          }

          if (!match) {
            existing.push(data);
          }
          this._requestService.saveRecipeCartItems(existing);
        } else {
          var tmp_array = [];
          tmp_array.push(data);
          this._local.set('cartRecipeItems', JSON.stringify(tmp_array));
          this._requestService.saveRecipeCartItems(tmp_array);
        }
        break;
      case 'product':
        data = {
          ProductId: selectedItem.ProductId,
          ProductTitle: selectedItem.ProductTitle,
          ProductOldPrice: selectedItem.ProductOldPrice + this.extrasTotal,
          ProductNewPrice: selectedItem.ProductNewPrice,
          ProductTotal: this.recipeTotal,
          ProductImageUrl: selectedItem.ProductImageUrl,
          ProductUnits: selectedItem?.ProductUnits,
          IsDiscountedItem: selectedItem.isDiscountedItem,
          ProductQuantity: this.quantity,
          ProductExtraObject: this.extraArray,
          ForDelivery: selectedItem?.ForDelivery,
          ForCollection: selectedItem?.ForCollection,
        };
        existing = JSON.parse(this._local.get('cartProductItems'));
        if (existing != null && existing.length != 0) {
          let match: boolean = false;
          if (!selectedItem.isDiscountedItem) {
            existing.forEach((val) => {
              if (val.ProductId == selectedItem.ProductId) {
                if (val.ProductExtraObject.length == this.extraArray.length) {
                  let j: number = 0;
                  for (let i = 0; i < this.extraArray.length; i++) {
                    if (
                      val.ProductExtraObject[i].Title ===
                      this.extraArray[i].Title
                    ) {
                      j++;
                    }
                  }
                  if (j >= this.extraArray.length) {
                    val.ProductTotal =
                      val.ProductTotal + this.recipePrice * this.quantity;
                    val.ProductQuantity = val.ProductQuantity + this.quantity;
                    match = true;
                  }
                }
              }
            });
          }
          if (!match) {
            existing.push(data);
          }
          this._requestService.saveProductCartItems(existing);
        } else {
          var tmp_array = [];
          tmp_array.push(data);
          this._local.set('cartProductItems', JSON.stringify(tmp_array));
          this._requestService.saveProductCartItems(tmp_array);
        }
        break;
    }
    this.changeDetector.detectChanges();
    this.extraArray = [];
    this.selectedRecipeTitle = this.filteredRecipe
      ? selectedItem.RecipeTitle
      : selectedItem.ProductTitle;
    this.addedTocart = false;
    this.popupService.addedToCartSuccessful.next(data);
    if (typeof fbq !== 'undefined') {
      fbq('track', 'Add to cart', { item: selectedItem.RecipeTitle });
    }

    this.changeDetector.detectChanges();
    this.showPopup = false;
    this.addedTocart = true;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.addedTocart = false;
    }, 1300);

    if (this._local.getSessionItem('orderId') != 'undefined') {
      this._local.setSessionItem('amended', true);
    }
  }

  quantityControl(flag) {
    let extrasTotal = 0;
    if (flag == '+') {
      this.quantity = this.quantity + 1;
    } else {
      if (this.quantity !== 1) {
        this.quantity = this.quantity - 1;
      }
    }
    this.extraArray.forEach((val) => {
      extrasTotal += val.Price * val.Quantity;
    });
    this.extrasTotal = extrasTotal;
    this.recipeTotal =
      this.recipePrice * this.quantity + this.extrasTotal * this.quantity;
  }

  selectExtra(item, recipe_price, extra?) {
    //MAX Quantity Check Check If Item is Already added and maximum quantity is selected already IF selected return do nothing
    if (
      this.extraArray.length &&
      extra?.maxQuantity &&
      extra?.maxQuantity != 1
    ) {
      let i = 0;
      this.extraArray.forEach((val) => {
        if (val.Key == item.Key) {
          i += val.Quantity;
        }
      });
      if (i >= extra?.maxQuantity) return;
    }

    let sum: number = 0;
    this.extraArrayChooseOnes = 0;
    this.recipePrice = recipe_price;
    if (Number.isNaN(this.recipeTotal)) {
      this.recipeTotal = 0;
    }

    let indexFlag = 0;

    if (
      (this.extraArray.length != 0 &&
        item.Key.toLowerCase().includes('choose')) ||
      extra?.maxQuantity == 1
    ) {
      this.extraArray.forEach((val) => {
        if (val.Key == item.Key && val.Key == item.Key) {
          this.extraArray.splice(this.extraArray.indexOf(val), 1);
        }
      });

      item.Quantity = 1;
      this.extraArray.push(item);
    } else if (item.Key.toLowerCase().includes('optional')) {
      this.extraArray.forEach((val) => {
        if (val.Title == item.Title && val.Key == item.Key) {
          this.extraArray.splice(this.extraArray.indexOf(val), 1);
          indexFlag = 1;
        }
      });
      if (!indexFlag) {
        item.Quantity = 1;
        this.extraArray.push(item);
      }
    } else if (this.extraArray.length != 0) {
      if (item.Quantity < 9) {
        if (this.extraArray.indexOf(item) >= 0) {
          item.Quantity += 1;
        } else {
          item.Quantity += 1;
          this.extraArray.push(item);
        }
      }
    } else {
      item.Quantity += 1;
      this.extraArray.push(item);
    }

    this.extraArray.forEach((val) => {
      sum += val.Price * val.Quantity;
      if (val.Key.toLowerCase().includes('choose')) {
        this.extraArrayChooseOnes += 1;
      }
    });

    this.extrasTotal = sum;
    this.recipeTotal =
      this.recipePrice * this.quantity + this.extrasTotal * this.quantity;
  }

  multipleExtrasQuantity(item: any) {
    let extraTotal = 0;
    if (item.Quantity == 1) {
      item.Quantity -= 1;
      this.extraArray.splice(this.extraArray.indexOf(item), 1);
    } else {
      if (item.Quantity > 0) {
        item.Quantity -= 1;
      }
    }
    this.extraArray.forEach((val) => {
      extraTotal += val.Price * val.Quantity;
    });
    this.extrasTotal = extraTotal;
    this.recipeTotal =
      this.recipePrice * this.quantity + this.extrasTotal * this.quantity;
  }

  productQuantityControl(flag) {
    if (flag == '+') {
      this.quantity = this.quantity + 1;
      this.productTotal += this.productPrice;
    } else {
      if (this.quantity !== 1) {
        this.quantity = this.quantity - 1;
        this.productTotal -= this.productPrice;
      }
    }
  }

  selectProductExtra(item, product_price) {
    this.productPrice = product_price;
    if (Number.isNaN(this.productTotal)) {
      this.productTotal = 0;
    }

    let indexFlag = 0;

    if (this.extraArray.length != 0) {
      this.extraArray.forEach((val) => {
        if (val.Key == item.Key) {
          if (item.Price != 0) {
            this.productTotal -= val.Price * this.quantity;
          }

          this.extraArray.splice(this.extraArray.indexOf(val), 1);
          indexFlag = 1;
        }
      });

      if (indexFlag == 1) {
        if (item.Price != 0) {
          this.productTotal += item.Price * this.quantity;
        }
        this.extraArray.push(item);
      } else {
        if (item.Price != 0) {
          this.productTotal += item.Price * this.quantity;
        }
        this.extraArray.push(item);
      }
    } else {
      if (item.Price != 0) {
        this.productTotal += item.Price * this.quantity;
      }
      this.extraArray.push(item);
    }
    let sum: number = 0;
    this.extraArray.forEach((val) => {
      sum += val.Price;
    });
    this.productPrice += sum;
  }

  closeModel() {
    this.showPopup = false;
    this.closeExtrasModal.emit(false);
  }

  openNextOption(extra, item) {
    this.disableCartbtn = true;
    let newExtra: any = {
      Image: item.Img,
      Key: 'Choose One',
      Price: item.Price,
      Quantity: 1,
      Title: item.Item,
      cat: extra.Title,
      redirection: item.Redirection,
      Id: extra.Id,
    };
    this.extraArray.forEach((val, index, array) => {
      if (val.Id == newExtra.Id) {
        array.splice(this.extraArray.indexOf(val), array.length);
      }
    });
    this.extraArray.push(newExtra);

    this.selectedOptionsArray = [0];
    this.extraArray.forEach((value) => {
      if (value.redirection !== -1) {
        this.selectedOptionsArray.push(value.redirection);
        this.showExtrasAfterMenuBuilder = false;
      }
      if (value.redirection == -1) {
        this.disableCartbtn = false;
        this.showExtrasAfterMenuBuilder = true;
      }
    });
    this.openCategory(item.Redirection);
    this.getMenuBuilderTotal();
    document.getElementById(extra.Id.toString())?.scrollIntoView();
  }

  highLightItem(extraId, item): boolean {
    var result = false;
    this.extraArray.forEach((val) => {
      if (val.Title == item.Item && val.Id == extraId) {
        result = true;
      }
    });
    return result;
  }

  highLightCat(Id): boolean {
    var result = false;
    this.extraArray.forEach((val) => {
      if (val.Id == Id) {
        result = true;
      }
    });
    return result;
  }

  itemTitleReplace(extra) {
    var result = '';
    this.extraArray.forEach((val) => {
      if (val.Id == extra.Id) {
        result = val.Title;
      }
    });
    return result;
  }

  menuBuilderQuantity(flag) {
    let extrasTotal = 0;
    if (flag == '+') {
      this.quantity = this.quantity + 1;
    } else {
      if (this.quantity !== 1) {
        this.quantity = this.quantity - 1;
      }
    }
    this.getMenuBuilderTotal();
  }

  openCategory(Id) {
    this.selectedOptionId = Id;
  }

  getMenuBuilderTotal() {
    let sum = 0;
    this.extraArray.forEach((val) => {
      sum += val.Price * val.Quantity;
    });
    this.extrasTotal = sum;
    this.recipeTotal =
      this.recipePrice * this.quantity + this.extrasTotal * this.quantity;
  }

  kgQuantity() {
    this.recipeTotal =
      this.recipePrice * this.quantity + this.extrasTotal * this.quantity;
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  mergePopupChoose(item) {
    let indexFlag = 0;
    if (item.Key.toLowerCase().includes('choose')) {
      this.extraArray.forEach((val) => {
        if (val.redirection >= -1) return;
        if (val.Key == item.Key) {
          this.extraArray.splice(this.extraArray.indexOf(val), 1);
          this.extraArrayChooseOnes--;
          indexFlag = 1;
        }
      });
      if (indexFlag == 1) {
        this.extraArrayChooseOnes++;
        item.Quantity = 1;
        this.extraArray.push(item);
      } else {
        this.extraArrayChooseOnes++;
        item.Quantity = 1;
        this.extraArray.push(item);
      }
    }
    let sum = 0;
    this.extraArray.forEach((val) => {
      sum += val.Price * val.Quantity;
    });
    this.extrasTotal = sum;
    this.recipeTotal =
      this.recipePrice * this.quantity + this.extrasTotal * this.quantity;
  }

  mergePopupSelectExtras(item, extra?) {
    if (this.extraArray.length && extra?.maxQuantity) {
      let i = 0;
      this.extraArray.forEach((val) => {
        if (val.Key == item.Key) {
          i += val.Quantity;
        }
      });
      if (i >= extra?.maxQuantity) return;
    }
    if (this.extraArray.length != 0) {
      if (item.Quantity < 9) {
        if (this.extraArray.indexOf(item) >= 0) {
          item.Quantity += 1;
        } else {
          item.Quantity += 1;
          this.extraArray.push(item);
        }
      }
    } else {
      item.Quantity += 1;
      this.extraArray.push(item);
    }
    let sum = 0;
    this.extraArray.forEach((val) => {
      sum += val.Price * val.Quantity;
    });
    this.extrasTotal = sum;
    this.recipeTotal =
      this.recipePrice * this.quantity + this.extrasTotal * this.quantity;
  }

  mergePopupExtrasQuantityDecrease(item: any) {
    let extraTotal = 0;
    if (item.Quantity == 1) {
      item.Quantity -= 1;
      this.extraArray.splice(this.extraArray.indexOf(item), 1);
    } else {
      if (item.Quantity > 0) {
        item.Quantity -= 1;
      }
    }
    this.extraArray.forEach((val) => {
      extraTotal += val.Price * val.Quantity;
    });
    this.extrasTotal = extraTotal;
    this.recipeTotal =
      this.recipePrice * this.quantity + this.extrasTotal * this.quantity;
  }
}
