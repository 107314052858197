import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { popupService } from 'src/app/popup/popup.service';
import { ApiService } from 'src/app/service/api.service';
import { ExtraObject, Recipe } from 'src/Model/Product-Interfaces';
import { popupData } from 'src/app/popup/popup.service';
import { SelectDeliveryType } from '../select-delivery-type/select-delivery-type.service';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/service/request.service';
@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss'],
})
export class RecipeComponent implements OnInit, OnDestroy {
  showPopup = false;
  @Input() recipes: Recipe[] = [];
  @Input() openForNonExtras: boolean = false;
  @Input() addTocart: boolean = true;
  @Input() dineIn: boolean = false;
  @Input() doNotOpenDeliveryPopup: boolean = false;
  LandingPostcodeValidation: boolean;

  /* Select Delivery type */
  selectDeliveryType: boolean = false;
  DeliveryFrom;
  MinDeliveryTime;
  CollectionFrom;
  MinCollectionTime;
  /* Styles */
  ItemTitleColor;
  ItemDescriptionColor;
  ItemBackgroundColor;
  MenuCartColor: string;
  RecipePriceTextColor: string;
  RecipePriceBackgroundColor: string;
  RecipePriceBorderColor: string;
  SelectedButtonColor: string;
  SelectedButtonTextColor: string;
  deliveryTypePopup: boolean;
  webCusSub: Subscription;
  outOfStockLabelText: string;
  constructor(
    private apiService: ApiService,
    private _requestService: RequestService,
    private popupService: popupService,
    private selectService: SelectDeliveryType
  ) {}

  ngOnInit(): void {
    this.getWebCustomer();
  }

  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }

  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.outOfStockLabelText = res.TemplateData?.Menu?.OutOfStockLabelText;
      this.deliveryTypePopup = res.TemplateData?.DeliveryTypes?.Enabled;

      this.ItemBackgroundColor =
        res.TemplateData.DynamicStyle.SecondaryColors?.ItemBackgroundColor;
      this.ItemDescriptionColor =
        res.TemplateData.DynamicStyle.SecondaryColors?.ItemDescriptionColor;
      this.ItemTitleColor =
        res.TemplateData.DynamicStyle.SecondaryColors?.ItemTitleColor;
      this.LandingPostcodeValidation =
        res.TemplateData.CheckoutDetail?.LandingPostcodeValidation;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.MenuCartColor =
        res.TemplateData.DynamicStyle.SecondaryColors.MenuCartColor;

      this.RecipePriceTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.RecipePriceTextColor;

      this.RecipePriceBackgroundColor =
        res.TemplateData.DynamicStyle.SecondaryColors.RecipePriceBackgroundColor;

      this.RecipePriceBorderColor =
        res.TemplateData.DynamicStyle.SecondaryColors.RecipePriceBorderColor;
    });
  }

  getRecipeModal(recipe: Recipe) {
    console.log(this._requestService.deliveryType$.value)
    if (
      !this.doNotOpenDeliveryPopup &&
      this.deliveryTypePopup &&
      !this._requestService.deliveryType$.value.length
    ) {
      this.selectService.showPopup.next();
      return;
    }

    let renderedExtraList: Array<any> = [];
    let renderExtralistChooseOnes = 0;
    let extras: ExtraObject = recipe.RecipeExtraObject;

    if (!extras) {
      let popupdata: popupData = {
        MenuBuilder: extras?.MenuBuilder,
        eatIn: recipe.ForEatIn,
        dineIn: this.dineIn,
        filteredProduct: null,
        filteredRecipe: recipe,
        renderedExtraList: renderedExtraList,
        openForNonExtras: this.openForNonExtras,
        renderExtralistChooseOnes: renderExtralistChooseOnes,
        mergePopups: extras?.mergePopups,
        renderedMenuBuilder: extras?.Sections,
      };
      this.popupService.popUpData.next(popupdata);
      return;
    }

    if (typeof extras == 'string') {
      extras = JSON.parse(extras);
    }

    if (extras?.MenuBuilder && !extras.mergePopups) {
      renderedExtraList = extras?.Sections;
    } else if (!extras?.MenuBuilder && !extras.mergePopups) {
      Object.entries(extras).forEach(([key, value]) => {
        if (typeof value == 'boolean') return;
        if (value['MaxQuantity']) {
          value['Extras'].forEach((item) => {
            item.Key = key;
            item.Quantity = 0;
          });
          let param = {
            Key: key,
            maxQuantity: value['MaxQuantity'],
            extras: value['Extras'],
          };
          renderedExtraList.push(param);
          return;
        }
        if (typeof value !== 'string') {
          Object.entries(value).forEach(([k, v]) => {
            v.Key = key;
            v.Quantity = 0;
          });
        }
        let param = {
          Key: key,
          extras: value,
        };
        renderedExtraList.push(param);
      });

      renderedExtraList.forEach((extra) => {
        if (extra.Key.toLowerCase().includes('choose')) {
          renderExtralistChooseOnes += 1;
        }
      });
    } else {
      Object.entries(extras).forEach(([key, value]) => {
        if (typeof value == 'boolean') return;
        if (key == 'Sections') return;
        if (value['MaxQuantity']) {
          value['Extras'].forEach((item) => {
            item.Key = key;
            item.Quantity = 0;
          });
          let param = {
            Key: key,
            maxQuantity: value['MaxQuantity'],
            extras: value['Extras'],
          };
          renderedExtraList.push(param);
          return;
        }
        if (typeof value !== 'string') {
          Object.entries(value).forEach(([k, v]) => {
            v.Key = key;
            v.Quantity = 0;
          });
        }
        let param = {
          Key: key,
          extras: value,
        };
        renderedExtraList.push(param);
      });
      renderedExtraList.forEach((extra) => {
        if (extra.Key.toLowerCase().includes('choose')) {
          renderExtralistChooseOnes += 1;
        }
      });
    }

    let popupdata: popupData = {
      MenuBuilder: extras?.MenuBuilder,
      eatIn: recipe.ForEatIn,
      dineIn: this.dineIn,
      filteredProduct: null,
      filteredRecipe: recipe,
      renderedExtraList: renderedExtraList,
      openForNonExtras: this.openForNonExtras,
      renderExtralistChooseOnes: renderExtralistChooseOnes,
      mergePopups: extras?.mergePopups,
      renderedMenuBuilder: extras?.Sections,
    };
    this.popupService.popUpData.next(popupdata);
  }
}
